import '../styles/index.scss';
import { initializeButtonReact } from '../mainButtonInit';
import { initializeEnvTitleReact } from '../envLineInit';
import { initializeBurgerReact } from '../burgerInit';
import { ludoDomains } from '@ludo.ninja/core/build/ludoDomains';
import process from 'process';
var nftMintingUrl = "".concat(ludoDomains[process.env.ENV_VALUE]['app'], "/minting");
var ludoNftLink = document.getElementById('ludoNftLink');
ludoNftLink.href = nftMintingUrl;
initializeBurgerReact();
initializeEnvTitleReact();
initializeButtonReact({ id: 'mainPage', url: '/', label: 'Go to Main page', openInNewTab: false });
